<template>
  <div class="RequestDetail">
    <div class="row justify-center">
      <div class="col-auto">
        <q-btn-group
          :spread="isMobile"
          :class="{ 'fixed-bottom': isMobile, 'q-mt-md': !isMobile }"
          style="z-index:999;"
        >
          <q-btn icon="exit_to_app" class="buttonsBar" @click="exit" />
          <q-btn
            icon="account_tree"
            class="buttonsBar"
            @click="openHierarchy"
          />
          <q-btn
            icon="table_rows"
            class="buttonsBar"
            v-if="viewTable"
            @click="viewTable = !viewTable"
          />
          <q-btn
            icon="table_chart"
            class="buttonsBar"
            v-if="!viewTable"
            @click="viewTable = !viewTable"
          />
        </q-btn-group>
      </div>
    </div>

    <q-scroll-area style="height: 80vh;">
      <div v-if="viewTable">
        <q-card class="q-ma-sm" v-if="request">
          <q-card-section>
            <div class="row justify-start">
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Empresa: </span>
                <span>{{
                  `${request.branchCode} - ${request.branchName.toUpperCase()}`
                }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Solicitacao: </span>
                <span>{{ request.requestNumber }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Dt. Emissão: </span>
                <span>{{ format(request.issueDate, "date") }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Solicitante: </span>
                <span>{{ request.requester.toUpperCase() }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Local Entrega: </span>
                <span>{{ request.deliveryPlace.toUpperCase() }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">CEP: </span>
                <span>{{ request.zipCode }}</span>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Centro de Custo: </span>
                <span>{{ request.costCenter.toUpperCase() }}</span>
              </div>
              <!--
              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
                <span class="text-bold">Valor Total.: </span>
                <span>{{ format(request.amountOfRequest, "currency") }}</span>
              </div>
              -->
            </div>
          </q-card-section>
        </q-card>
        <DataTable
          v-if="request"
          :data="items"
          :columns="columns"
          :gridMobile="false"
          hidePagination
          class="q-mx-sm table"
          style="margin-bottom: 50px ;"
        />
      </div>
      <div v-else>
        <productsCard :data="request" />
      </div>
    </q-scroll-area>

    <div class="row justify-evenly q-mb-xl">
      <div class="col-auto">
        <q-btn color="green" icon="check" label="Aprovar" @click="approve" />
      </div>
      <div class="col-auto">
        <q-btn color="red" icon="block" label="Rejeitar" @click="reject" />
      </div>
    </div>
    <ApprovalHierarchy ref="ApprovalHierarchy" />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import DataTable from "@/components/DataTable";
import productsCard from "./productsCard";
import ApprovalHierarchy from "./ApprovalHierarchy";
import formatString from "@/helpers/formatString";

export default {
  components: {
    DataTable,
    productsCard,
    ApprovalHierarchy
  },
  data() {
    return {
      request: null,
      items: null,
      viewTable: true,
      columns: [
        {
          field: "item",
          label: "Item",
          align: "left",
          sortable: true
        },
        {
          field: "productDescription",
          label: "Produto",
          type: "string",
          align: "left",
          sortable: true
        },
        {
          field: "pcoControl",
          label: "Contr. PCO",
          type: "string",
          align: "left",
          sortable: true
        },
        {
          field: "descriptionClass",
          label: "Classe Orçamentária",
          align: "left",
          type: "string",
          sortable: true
        },
        {
          field: "um",
          label: "UM",
          type: "string",
          align: "left",
          sortable: true
        },
        {
          field: "quantity",
          label: "Qt.",
          align: "left",
          type: "number",
          sortable: true
        }
        /*
        {
          field: "expectedValue",
          label: "Vlr.Unit.",
          align: "left",
          type: "currency",
          sortable: true
        },
        {
          field: "expectedAmount",
          label: "Vlr.Tot.",
          align: "left",
          type: "currency",
          sortable: true
        }
        */
      ]
    };
  },
  computed: {
    requestId() {
      return this.$route.params.requestId;
    },
    isMobile() {
      return this.$q.screen.xs;
    }
  },
  methods: {
    ...mapActions("purchaseRequests", [
      "getRequestById",
      "approvesRejectsPurchaseRequest"
    ]),
    ...mapMutations("shared", ["setNotification"]),
    format(value, type, decimals) {
      return formatString(value, type, decimals);
    },
    exit() {
      this.$router.back();
    },
    openHierarchy() {
      this.$refs.ApprovalHierarchy.open(this.request.approvalHierarchy);
    },
    approve() {
      this.sendResponse(this.request, "approved", "APROVAR", "text-green");
    },
    reject() {
      this.sendResponse(this.request, "rejected", "REJEITAR", "text-red");
    },
    sendResponse(request, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> o Pedido ${request.requestNumber}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          try {
            await this.approvesRejectsPurchaseRequest({ request, response });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });
            this.$router.push({ name: "requests" });
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    }
  },
  async created() {
    try {
      const requests = await this.getRequestById(this.requestId);
      const items = requests?.items.map(item => ({
        ...item,
        descriptionClass:
          item.classe == undefined ? "-" : `${item.classe} - ${item.classeName}`
      }));

      this.items = items;
      this.request = requests;
    } catch (error) {
      console.log(error.message);
      this.$router.back();
    }
  }
};
</script>
<style lang="scss">
.RequestDetail {
  width: 100%;
  .buttonsBar {
    background: #11275b;
    color: #fff;
  }
}
</style>
